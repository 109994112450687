<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="600"
    :before-close="handleClose"
  >
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      :rules="rules"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名：" prop="userName">
        <EmployeeSelect
          v-model="ruleForm.userId"
          placeholder="请填写"
          @changeChecked="handleChangeSalesman"
        />
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="ruleForm.phone" disabled maxlength="48"></el-input>
      </el-form-item>
      <el-form-item label="部门：" prop="deptId">
        <el-input
          v-model="ruleForm.deptName"
          disabled
          maxlength="48"
        ></el-input>
      </el-form-item>
      <el-form-item label="不分配区域：" prop="servicePhone">
        <!-- <el-checkbox v-model="ruleForm.isResponsible"
          :true-label="1"
          :false-label="0"
          @change="handleCheckbox"></el-checkbox> -->
        <el-radio-group v-model="ruleForm.isResponsible">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="负责区域："
        v-if="ruleForm.isResponsible === 0"
        prop="provinceCityVOList"
      >
        <!-- <el-cascader ref="cascader"
          :options="regionList"
          style="width:100%"
          :props="props"
          v-model="ruleForm.responsibleArea"
          :disabled="ruleForm.isResponsible ? true : false"
          clearable></el-cascader> -->
        <!-- <el-select v-model="ruleForm.responsibleArea"
          filterable
          multiple
          placeholder="请选择">
          <el-option v-for="item in applicantStore"
            :key="item.dictKey"
            :label="item.dictValue"
            :value="item.dictKey"></el-option>
        </el-select> -->
        <div
          v-for="(item, index) in ruleForm.provinceCityVOList"
          :key="index"
          style="display: flex; align-items: center; margin-bottom: 8px"
        >
          <v-distpicker
            class="distpicker-all-box"
            :province="item.provinceName"
            :city="item.cityName"
            hide-area
            @selected="onSelected($event, index)"
          ></v-distpicker>
          <i
            class="el-icon-delete"
            style="
              color: #f64a2d;
              font-size: 16px;
              margin-left: 10px;
              cursor: pointer;
            "
            @click="handleDelResponsible(index)"
          ></i>
        </div>
        <el-button type="primary" plain @click="handleAddResponsible"
          >添加</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import { addClaimArea } from "@/api/SystemData";
import { provinceCity } from "@/utils/provinceCity";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import VDistpicker from "v-distpicker";
import { dictionaryBatch } from "@/api/policy";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: { EmployeeSelect, VDistpicker },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
          console.log(this.ruleForm);
        } else if (!v) {
          this.ruleForm = {
            deptId: "",
            isResponsible: 0,
            phone: "",
            responsibleArea: [],
            provinceCityVOList: [],
            userId: 0,
            userName: "",
            userTenantId: "",
          };
          console.log(this.ruleForm);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      props: { multiple: true, value: "label" },
      ruleForm: {
        deptId: "",
        isResponsible: 0,
        phone: "",
        responsibleArea: [],
        provinceCityVOList: [],
        userId: 0,
        userName: "",
        userTenantId: "",
      },
      regionList: provinceCity,
      rules: {
        userName: [
          { required: true, message: "请选择租户", trigger: "change" },
        ],
        phone: [{ required: true, message: "请选择租户", trigger: "change" }],
        deptId: [{ required: true, message: "请选择租户", trigger: "change" }],
        responsibleArea: [
          { required: true, message: "请选择负责区域", trigger: "change" },
        ],
      },
      loading: true,
      options: [],
      flatRegion: [],
      applicantStore: [],
    };
  },
  async created() {
    //  this.flatRegion = this.flatData(provinceCity);
    this.init();
  },
  methods: {
    init() {
      dictionaryBatch({ codes: "applicantStore" }).then((res) => {
        if (res && res.data) {
          const { applicantStore } = res.data;
          this.applicantStore = applicantStore;
        }
      });
    },
    handleChangeSalesman(data) {
      this.ruleForm.userName = data.title;
      this.ruleForm.phone = data.extension.phone;
      this.ruleForm.deptId = data.parentId;
      this.ruleForm.deptName = data.extension.deptVOS.find((el) => {
        return data.parentId === el.id;
      }).deptName;
      this.ruleForm.userTenantId = data.extension.deptVOS[0].tenantId;
      this.changeValidateField("userName");
    },
    changeValidateField(type) {
      this.$refs["ruleForm"].validateField(type);
    },
    handleCheckbox() {
      if (!this.ruleForm.isResponsible) {
        this.ruleForm.provinceCityVOList = [];
      }
    },
    handleRegionChange(e) {
      let cityList = this.$refs["cascader"].getCheckedNodes();
      const list = cityList.map((item) => {
        return item.value;
      });
      const selectCityList = [];
      cityList.forEach((item) => {
        const proCode = item.value.toString().substr(2);
        if (proCode === "00") {
          selectCityList.push(item.value);
        } else {
          const code = (item.value.toString().substr(0, 2) + "00") * 1;
          if (!list.includes(code)) {
            selectCityList.push(item.value);
          }
        }
      });
      this.ruleForm.responsibleArea = selectCityList.length
        ? selectCityList.join(",")
        : "";
      console.log(this.ruleForm.responsibleArea);
      // if (selectCityList && selectCityList.length) {
      //   let arr = [];
      //   selectCityList.map(el => {
      //     this.flatRegion.map(ele => {
      //       if (ele.value === el) arr.push(ele.label);
      //     });
      //   });
      //   this.ruleForm.responsibleArea = arr.join(',');
      // }
    },
    flatData(data) {
      return data.reduce((prev, next) => {
        return prev.concat(
          Array.isArray(next.children) ? this.flatData(next.children) : next
        );
      }, []);
    },
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      if (
        !this.ruleForm.isResponsible &&
        (!this.ruleForm.provinceCityVOList ||
          !this.ruleForm.provinceCityVOList.length ||
          this.ruleForm.provinceCityVOList.find((val) => !val.provinceName) ||
          this.ruleForm.provinceCityVOList.find((val) => !val.cityName))
      ) {
        this.$message.error("请选择完整的负责区域！");
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.isResponsible) {
            this.ruleForm.responsibleAreaProvince =
              this.ruleForm.provinceCityVOList.map((val) => val.provinceName);
            this.ruleForm.responsibleArea =
              this.ruleForm.provinceCityVOList.map((val) => val.cityName);
          }
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.isResponsible = data.isResponsible ? 1 : 0;
          addClaimArea({ ...data }).then((res) => {
            this.handleClose();
            this.$emit("changeList", 1);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          deptId: "",
          isResponsible: 0,
          phone: "",
          responsibleArea: [],
          provinceCityVOList: [],
          userId: 0,
          userName: "",
          userTenantId: "",
        };
      }
      this.$forceUpdate();
    },
    onSelected(e, ind) {
      this.ruleForm.provinceCityVOList[ind].provinceName = e.province.value;
      this.ruleForm.provinceCityVOList[ind].cityName = e.city.value;
      this.$forceUpdate();
    },
    handleAddResponsible() {
      console.log(this.ruleForm.provinceCityVOList);
      if (!this.ruleForm.provinceCityVOList) {
        this.ruleForm.provinceCityVOList = [
          {
            provinceName: "",
            cityName: "",
          },
        ];
        this.$forceUpdate();
        return false;
      }
      this.ruleForm.provinceCityVOList.push({
        provinceName: "",
        cityName: "",
      });
      this.$forceUpdate();
    },
    handleDelResponsible(ind) {
      this.ruleForm.provinceCityVOList.splice(ind, 1);
    },
  },
};
</script>
<style lang="scss">
.el-cascader-panel {
  height: 300px;
}
</style>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
</style>
